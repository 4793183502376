// src/components/OtpComponent.js
import React, { useState } from 'react';
import axios from 'axios';

const OtpComponent = () => {
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [message, setMessage] = useState('');
    const [otpSent, setOtpSent] = useState(false);

    const sendOtp = async () => {
        try {
            const response = await axios.post('http://localhost:8000/send-otp/', { email });
            setMessage(response.data.message);
            setOtpSent(true);
        } catch (error) {
            setMessage('Error sending OTP');
        }
    };

    const verifyOtp = async () => {
        try {
            const response = await axios.post('http://localhost:8000/verify-otp/', { email, otp });
            setMessage(response.data.message);
        } catch (error) {
            setMessage('Invalid OTP');
        }
    };

    return (
        <div style={{ padding: '20px' }}>
            <h2>OTP Authentication</h2>
            <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{ marginBottom: '10px', padding: '10px', width: '300px' }}
            />
            <button onClick={sendOtp} style={{ marginBottom: '20px', padding: '10px' }}>
                Send OTP
            </button>

            {otpSent && (
                <>
                    <input
                        type="text"
                        placeholder="Enter OTP"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        style={{ marginBottom: '10px', padding: '10px', width: '300px' }}
                    />
                    <button onClick={verifyOtp} style={{ padding: '10px' }}>
                        Verify OTP
                    </button>
                </>
            )}

            {message && <p>{message}</p>}
        </div>
    );
};

export default OtpComponent;