import React from 'react';
import { Box, Typography } from '@mui/material';
import _404Image from '../../assets/img/404.jpg'; 
import { Link } from 'react-router-dom';
import LogoImage from '../../assets/img/logo-white.png';

const NotFoundScreen: React.FC = () => {
  return (
    <Box
      sx={{
        height: '100vh',
        backgroundImage: `url(${_404Image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative', 
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        color: 'white',
        textAlign: 'center',
      }}
    >
        
      {/* Dark overlay */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black
          zIndex: 1, // Ensure overlay is above the background image
        }}
      />
      
      <Box sx={{ zIndex: 2 }}> 
      <a href='/'>
      <Box
        component="img"
        src={LogoImage}
        alt="Logo Image"
        sx={{
            width: '30%',
            zIndex: 0
        }}
      /></a>
        <Typography variant="h1" component="h1">
          404 - Page Not Found
        </Typography>
        <Typography variant="h6" component="h2">
          Sorry, the page you are looking for does not exist.
        </Typography>
        <Link style={{
            color: 'white'
        }} to='/'>Back to Home</Link>
      </Box>
    </Box>
  );
};

export default NotFoundScreen;