import { createBrowserRouter } from "react-router-dom";
import HomeScreen from "./screens/Home";
import Register from "./screens/Register";
import Login from "./screens/Login";
import OTP from "./screens/OTP";
import { RouterProvider, createRouter } from '@tanstack/react-router'
import PrivacyPolicyScreen from "./screens/PrivacyPolicy";
import AboutScreen from "./screens/About";
import TermsOfServiceScreen from "./screens/TermsOfService";
import ContactScreen from "./screens/Contact";
import HowScreen from "./screens/How";
import NotFoundScreen from "./screens/404";

export const router = createBrowserRouter([
    {
      path: "/",
      element: <HomeScreen />,
    },
    {
        path: "/register",
        element: <Register />,
    },
    {
        path: "/login",
        element: <Login />,
    },
    {
        path: "/how",
        element: <HowScreen />,
    },
    {
      path: "/otp",
      element: <OTP />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicyScreen />,
    },
    {
        path: "/about",
        element: <AboutScreen />, 
    },
    {
        path: "/terms-of-service",
        element: <TermsOfServiceScreen />, 
    },
    {
        path: "/contact",
        element: <ContactScreen />, 
    },
    {
      path: "*", 
      element: <NotFoundScreen />
    }
  ]);