// src/components/Register/Register.tsx
import React from 'react';
import FormComponent from '../../components/Register/FormComponent';
import Box from '@mui/material/Box';
import RegistrationImage from '../../assets/img/register.jpg';
import { Typography } from '@mui/material';
import useAuth from '../../hooks/use-auth';

const Register: React.FC = () => {
  const { register } = useAuth();
  const handleRegistration = (data: { username: string; password: string; email: string }) => {
    register(data);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '100vh',
        backgroundImage: `url(${RegistrationImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        position: 'relative',
        padding: 2,
      }}
    >
      <Box
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.9)', 
          borderRadius: 2,
          padding: 3,
          boxShadow: 3,
          zIndex: 1,
          marginTop: '20px', // Space between the title and the form
          width: '100%', // Full width for the form container
          maxWidth: '400px', // Limit the maximum width
        }}
      >
        <FormComponent />
      </Box>
    </Box>
  );
};

export default Register;