// src/pages/TermsOfService.tsx
import React from 'react';
import { Box, Typography } from '@mui/material';
import Footer from '../../components/Shared/Footer';
import Header from '../../components/Shared/Header';

const TermsOfServiceScreen: React.FC = () => {
  return (
    <>
      <Header />
      <Box
        sx={{
          padding: '2rem',
          maxWidth: '800px',
          margin: 'auto',
          backgroundColor: 'white',
          overflow: 'none',
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Terms of Service for DocuLearn
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Effective Date:</strong> <span>25 November 2024</span>
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to DocuLearn! These Terms of Service govern your use of our services. By accessing or using our services, you agree to comply with these terms. If you do not agree with any part of these terms, you must not use our services.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          1. Acceptance of Terms
        </Typography>
        <Typography variant="body1" paragraph>
          By using our services, you confirm that you are at least 18 years old or have the consent of a parent or guardian. You agree to use our services in accordance with all applicable laws and regulations.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          2. User Accounts
        </Typography>
        <Typography variant="body1" paragraph>
          To access certain features of our services, you may be required to create an account. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          3. Intellectual Property
        </Typography>
        <Typography variant="body1" paragraph>
          All content, trademarks, and other intellectual property on our services are owned by or licensed to DocuLearn. You may not use, reproduce, or distribute any content without our prior written consent.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          4. User Conduct
        </Typography>
        <Typography variant="body1" paragraph>
          You agree not to engage in any of the following prohibited activities:
        </Typography>
        <Typography variant="body1" paragraph>
          - Using the services for any unlawful purpose.
          <br />
          - Interfering with or disrupting the security or integrity of our services.
          <br />
          - Attempting to gain unauthorized access to our systems or networks.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          5. Limitation of Liability
        </Typography>
        <Typography variant="body1" paragraph>
          To the fullest extent permitted by law, DocuLearn shall not be liable for any indirect, incidental, or consequential damages arising from your use of our services.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          6. Changes to Terms
        </Typography>
        <Typography variant="body1" paragraph>
          We reserve the right to modify these Terms of Service at any time. We will notify you of any changes by posting the new terms on this page. Your continued use of our services after any changes constitutes your acceptance of the new terms.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          7. Governing Law
        </Typography>
        <Typography variant="body1" paragraph>
          These Terms of Service shall be governed by and construed in accordance with the laws of South Africa. Any disputes arising from these terms shall be resolved in the courts of South Africa.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          8. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions about these Terms of Service, please contact us at:
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Email:</strong> support@doculearn.com
          <br />
        </Typography>

        <Typography variant="body1" paragraph>
          By using our services, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service.
        </Typography>
      </Box>
      <Footer />
    </>
  );
};

export default TermsOfServiceScreen;