import { useMutation } from '@tanstack/react-query';
import axiosInstance from './utils/axiosInstance';
import { LoginData, RegisterData } from '../types/AuthTypes';

const useAuth = () => {

    const verifyOtpMutation = useMutation({
        mutationKey: ['verifyOtp'],
        mutationFn: async (otp: string) => {
          const response = await axiosInstance.post('/verify-otp', { otp });
          return response.data; 
        },
        onError: (error) => {
          console.error('OTP verification error:', error.message || 'Verification failed');
        },
    });

    const sendOtpMutation = useMutation({
        mutationKey: ['sendOtp'],
        mutationFn: async (email: string) => {
          const response = await axiosInstance.post('/send-otp', { email });
          return response.data; 
        },
        onError: (error) => {
          console.error('Send OTP error:', error.message || 'Sending OTP failed');
        },
      });

  const loginMutation = useMutation({
    mutationKey: ['login'],
    mutationFn: async ({ username, password }: LoginData) => {
      const response = await axiosInstance.post('/login/', { username, password });
      return response.data; 
    },
    onError: (error) => {
      console.error('Login error:', error.message || 'Login failed');
    },
  });

  const registerMutation = useMutation({
    mutationKey: ['register'],
    mutationFn: (userData: RegisterData) => {
      const response = axiosInstance.post('/register/', userData);
      return response; 
    },
    onError: (error) => {
      console.error('Registration error:', error.message || 'Registration failed');
    },
  });

  const resetPasswordMutation = useMutation({
    mutationKey: ['resetPassword'],
    mutationFn: async (email: string) => {
      const response = await axiosInstance.post('/reset-password', { email });
      return response.data; 
    },
    onError: (error) => {
      console.error('Password reset error:', error.message || 'Password reset failed');
    },
  });

  return {
    sendOtp: sendOtpMutation.mutate,
    verifyOtp: verifyOtpMutation.mutate,
    login: loginMutation.mutate,
    register: registerMutation.mutate,
    resetPassword: resetPasswordMutation.mutate,
    loginStatus: loginMutation.status,
    registerStatus: registerMutation.status,
    resetPasswordStatus: resetPasswordMutation.status,
    sendOtpStatus: sendOtpMutation.status,
    verityOtpStatus: verifyOtpMutation.status,
    loginError: loginMutation.error,
    registerError: registerMutation.error,
    resetPasswordError: resetPasswordMutation.error,
    sendOtpError: sendOtpMutation.error,
    verifyOtpError: verifyOtpMutation.error
  };
};

export default useAuth;