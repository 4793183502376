// src/pages/PrivacyPolicy.tsx
import React from 'react';
import { Box, Typography } from '@mui/material';
import Footer from '../../components/Shared/Footer';
import Header from '../../components/Shared/Header';

const PrivacyPolicyScreen: React.FC = () => {
  return (
    <>
      <Header />
      <Box
        sx={{
          padding: '2rem',
          maxWidth: '800px',
          margin: 'auto',
          backgroundColor: 'white',
          overflow: 'hidden',
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Privacy Policy for DocuLearn
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Effective Date:</strong> <span>25 November 2024</span>
        </Typography>
        <Typography variant="body1" paragraph>
          At DocuLearn, we are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines our practices regarding the collection, use, and sharing of your information, as well as your rights under applicable privacy laws, including the Protection of Personal Information Act (POPIA) in South Africa and the General Data Protection Regulation (GDPR) in Europe.
        </Typography>
        
        <Typography variant="h5" component="h2" gutterBottom>
          1. Information We Collect
        </Typography>
        <Typography variant="body1" paragraph>
          We may collect personal information from you when you use our services, including but not limited to:
        </Typography>
        <Typography variant="body1" paragraph>
          - Name
          <br />
          - Email address
          <br />
          - Phone number
          <br />
          - Any other information you provide to us voluntarily
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          2. How We Use Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We use your information for the following purposes:
        </Typography>
        <Typography variant="body1" paragraph>
          - To provide and maintain our services
          <br />
          - To communicate with you, including responding to your inquiries
          <br />
          - To improve our services and user experience
          <br />
          - To comply with legal obligations
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          3. Legal Basis for Processing Personal Data (GDPR)
        </Typography>
        <Typography variant="body1" paragraph>
          If you are located in the European Economic Area (EEA), our legal basis for collecting and using your personal information depends on the information we collect and the context in which we collect it. We may process your personal data because:
        </Typography>
        <Typography variant="body1" paragraph>
          - We need to perform a contract with you
          <br />
          - You have given us permission to do so
          <br />
          - The processing is in our legitimate interests and it's not overridden by your rights
          <br />
          - To comply with the law
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          4. Sharing Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We do not sell or rent your personal information to third parties. We may share your information with:
        </Typography>
        <Typography variant="body1" paragraph>
          - Service providers who assist us in operating our website and services
          <br />
          - Legal authorities if required by law or to protect our rights
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          5. Your Rights Under POPIA and GDPR
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Under the POPI Act:</strong>
          <br />
          - You have the right to access your personal information.
          <br />
          - You can request the correction of your personal information if it is inaccurate.
          <br />
          - You have the right to object to the processing of your personal information.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Under the GDPR:</strong>
          <br />
          - You have the right to access, rectify, or erase your personal data.
          <br />
          - You can restrict or object to the processing of your personal data.
          <br />
          - You have the right to data portability.
        </Typography>
        <Typography variant="body1" paragraph>
          To exercise these rights, please contact us using the information provided below.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          6. Data Security
        </Typography>
        <Typography variant="body1" paragraph>
          We take the security of your personal information seriously and implement appropriate technical and organizational measures to protect it from unauthorized access, use, or disclosure.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          7. International Data Transfers
        </Typography>
        <Typography variant="body1" paragraph>
          If you are located outside of South Africa or the EEA, please note that your information may be transferred to and processed in South Africa or other countries that may not have the same data protection laws as your jurisdiction. We will take steps to ensure that your information receives an adequate level of protection.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          8. Changes to This Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          9. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions about this Privacy Policy, please contact us at:
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Email:</strong> support@doculearn.com
          <br />
        </Typography>

        <Typography variant="body1" paragraph>
          By using our services, you consent to the collection and use of your information in accordance with this Privacy Policy. Thank you for trusting DocuLearn with your personal information.
        </Typography>
      </Box>
      <Footer />
    </>
  );
};

export default PrivacyPolicyScreen;