// src/components/BlogPreview.tsx
import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import uploadImage from '../../assets/img/upload.jpg';
import { useTheme } from '@mui/material/styles';

interface BlogPreviewProps {
  title: string;
  description: string;
  index: number;
}

const BlogPreview: React.FC<BlogPreviewProps> = ({ title, description, index }) => {

  const theme = useTheme();

  return (
    <Box
      sx={{
        padding: 2,
        backgroundColor: '#fff',
        borderRadius: 1,
        boxShadow: 1,
        color: 'black',
        textAlign: 'center',
        backgroundPosition: title === 'Select What you want' ? 'center' : 'top',
        borderRight: `8px solid ${theme.palette.primary.main}`,
        borderLeft: `8px solid ${theme.palette.secondary.main}`,
      }}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'row', 
        justifyContent: 'flex-start', 
        mb: 1
      }}>
      <Box style={{ padding: '4px' }}>
        <Button 
          variant="outlined" 
          color="secondary" 
          style={{ borderRadius: '50px', marginRight: '16px', border: '4px solid #27c71b', fontWeight: 'bolder' }} 
          sx={{
            boxShadow: 3,  
            transition: 'box-shadow 0.3s', 
            '&:hover': {
              boxShadow: 6, 
            },
          }}
        >
          {index}
        </Button>
      </Box>
      <Typography variant="h6" component="h4" sx={{ marginBottom: 1 }}>
        {title}
      </Typography>
      </Box>
      <Typography variant="body2" sx={{ color: 'black' }}>
        {description}
      </Typography>
    </Box>
  );
};

export default BlogPreview;