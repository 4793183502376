import React from 'react';
import { Box, Typography } from '@mui/material';
import Footer from '../../components/Shared/Footer';
import Header from '../../components/Shared/Header';

const AboutUs: React.FC = () => {
  return (
    <>
    <Header />
    <Box
      sx={{
        padding: '2rem',
        maxWidth: '800px',
        margin: 'auto',
        backgroundColor: 'white',
        overflow: 'hidden' 
      }}
    >
      <Typography variant="h4" component="h1" gutterBottom>
        About Us
      </Typography>
      <Typography variant="body1" paragraph>
        Welcome to DocuLearn!
      </Typography>
      <Typography variant="body1" paragraph>
        I’m a solo and technical founder based in Johannesburg, South Africa. The spark for DocuLearn ignited during my journey as a freelancer, where I found myself overwhelmed with the daunting task of creating extensive documentation for all the code I had written. As a one-person team, I realized that hiring a technical writer wasn't a feasible option, and I knew I needed a solution that would streamline this labor-intensive process.
      </Typography>
      <Typography variant="body1" paragraph>
        At the time, I was also preparing for the Azure 305 Exam to become a certified solutions architect. Balancing the demands of documentation with my studies was challenging, and I quickly recognized that every moment counted. It was in this context that the idea for DocuLearn was born—a tool designed to simplify the documentation process, allowing developers like myself to focus more on what they do best: coding.
      </Typography>
      <Typography variant="body1" paragraph>
        DocuLearn is built with the understanding that writing documentation can often feel like a time-consuming exercise. We aim to empower freelancers, small teams, and developers by providing an efficient way to create, manage, and share documentation that enhances productivity and fosters collaboration.
      </Typography>
      <Typography variant="body1" paragraph>
        With DocuLearn, you can save valuable time and effort, helping you to concentrate on your projects and achieve your goals. Join us on this journey to make documentation easier and more accessible for everyone!
      </Typography>
      <Typography variant="body1" paragraph>
        Thank you for choosing DocuLearn. Let’s transform the way we document together!
      </Typography>
    </Box>
    <Footer />
    </>
  );
};

export default AboutUs;