// src/App.tsx
import React from 'react';
import CallToAction from '../../components/Home/CallToAction';
import Box from '@mui/material/Box';
import BlogPreview from '../../components/Home/BlogPreview';
import Typography from '@mui/material/Typography';
import Header from '../../components/Shared/Header';
import Footer from '../../components/Shared/Footer';
import uploadImage from '../../assets/img/upload.jpg';
import codePage from '../../assets/img/codepage.jpg';
import selectImage from '../../assets/img/select.jpg';

const HowScreen: React.FC = () => {
  return (
    <div>
      <Header />
      
      {/* Blog Section */}
      <Box sx={{ padding: 1, backgroundColor: '#f9f9f9' }}>
        
      </Box>
      <Footer />
    </div>
  );
};

export default HowScreen;