// src/axiosInstance.ts
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL; 
const XAPIKey = process.env.REACT_APP_X_API_KEY; 

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'x-api-key': XAPIKey,
    'Content-Type': 'application/json',
  },
});



export default axiosInstance;