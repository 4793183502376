// src/App.tsx
import React from 'react';
import CallToAction from '../../components/Home/CallToAction';
import Box from '@mui/material/Box';
import BlogPreview from '../../components/Home/BlogPreview';
import Typography from '@mui/material/Typography';
import Header from '../../components/Shared/Header';
import Footer from '../../components/Shared/Footer';
import uploadImage from '../../assets/img/upload.jpg';
import codePage from '../../assets/img/codepage.jpg';
import selectImage from '../../assets/img/select.jpg';

const HomeScreen: React.FC = () => {
  return (
    <div>
      <Header />
      {/* Call to Action Section */}
      <CallToAction />
      
      {/* Blog Section */}
      <Box sx={{ padding: 1, backgroundColor: '#f9f9f9' }}>
        <Typography variant="h5" color="secondary" component="h3" sx={{ textAlign: 'center', m: 1 }}>
          Steps to create Documentation
        </Typography>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: { xs: '1fr', md: 'repeat(3, 1fr)' },
            gap: 2,
          }}
        >
          <BlogPreview title="Upload Zip File" description="Upload zip file with Code, only zip files are allowed." index={1} />
          <BlogPreview title="Get a Code Page Link" description="Get a Link to the code page. " index={2} />
          <BlogPreview title="Select What you want" description="Choose from Documentation, Tutorial, Code Reviews and more..." index={3} />
        </Box>
      </Box>
      <Footer />
    </div>
  );
};

export default HomeScreen;