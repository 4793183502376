// src/pages/Contact.tsx
import React from 'react';
import { Box, Typography } from '@mui/material';
import Footer from '../../components/Shared/Footer';
import Header from '../../components/Shared/Header';
import { useTheme } from '@mui/material/styles';

const ContactScreen: React.FC = () => {
    const theme = useTheme();
  return (
    <>
      <Header />
      <Box
        sx={{
          padding: '2rem',
          maxWidth: '800px',
          margin: 'auto',
          backgroundColor: 'white',
          overflow: 'hidden',
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          We would love to hear from you! If you have any questions, feedback, or inquiries, please feel free to reach out.
        </Typography>
        <Typography variant="body1" paragraph>
          You can contact us via email at: <strong>support@doculearn.com</strong>
        </Typography>
        <Typography variant="body1" paragraph>
          Alternatively, you can fill out the contact form below, and we will get back to you as soon as possible.
        </Typography>
        <Box
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '1rem',
          }}
        >
          <input
            type="text"
            placeholder="Your Name"
            style={{
              padding: '0.5rem',
              marginBottom: `1px solid ${theme.palette.primary.main}`,
              borderRadius: '4px',
            }}
            required
          />
          <input
            type="email"
            placeholder="Your Email"
            style={{
              padding: '0.5rem',
              marginBottom: '1rem',
              border: `1px solid ${theme.palette.primary.main}`,
              borderRadius: '4px',
            }}
            required
          />
          <textarea
            placeholder="Your Message"
            style={{
              padding: '0.5rem',
              marginBottom: '1rem',
              border: `1px solid ${theme.palette.primary.main}`,
              borderRadius: '4px',
              minHeight: '100px',
            }}
            required
          />
          <button
            type="submit"
            style={{
              padding: '0.5rem',
              backgroundColor: theme.palette.primary.main,
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            Send Message
          </button>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default ContactScreen;