// src/components/CallToAction.tsx
import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import FreebieImage from '../../assets/img/freebie.jpg'; 
import CodeImage from '../../assets/img/code.jpg';
import { FileUpload } from '@mui/icons-material';
import UploadModal from './UploadModal';

const CallToAction: React.FC = () => {
  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);

  const handleUploadClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleSignUpClick = () => {
    navigate("/register");
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: 'center',
        justifyContent: 'center',
        padding: 4,
        background: `linear-gradient(45deg, rgba(39, 199, 27, 0.8) 20%, rgba(70, 43, 182, 0.8) 60%, 
        rgba(39, 199, 27, 0.8) 40%)`,
        borderRadius: 2,
        boxShadow: 1,
        textAlign: 'center',
      }}
    >
      <Box sx={{ flex: 1, marginBottom: { xs: 3, md: 0 }, textAlign: { xs: 'center', md: 'left' } }}>
        <Typography variant="h4" component="h2" sx={{ marginBottom: 2 }}>
          Create Documentation in Seconds!
        </Typography>
        <Typography variant="body2" sx={{ marginTop: 3, marginBottom: 1 }}>
          Upload a ZIP file to get your free documentation!
        </Typography>
        <Box sx={{
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
          gridColumn: 'revert'
        }}>
        
        <Button 
            variant="contained" 
            color="primary" 
            onClick={handleUploadClick}
            sx={{
              borderRadius: '50px',
              padding: '10px 20px', 
              fontWeight: 'bold', 
              fontSize: '1.1rem', 
              textTransform: 'none', 
              justifyContent: 'space-around',
              mb: 2,
              mt: 1,
              background: 'linear-gradient(45deg, #462bb6 30%, #27c71b,  90%)', 
              color: 'white',
              '&:hover': {
                background: 'linear-gradient(45deg, #27c71b 30%, #462bb6 90%)',
              },
            }}
          >
            Upload
          </Button>
        <UploadModal open={modalOpen} onClose={handleCloseModal} />
        <Typography variant="body1" sx={{ marginBottom: 3 }}>
          Sign up now to receive exclusive updates and offers directly to your inbox.
        </Typography>
        <Button variant="contained" color="primary"
        
          sx={{
            borderRadius: '50px',
            padding: '4px 12px', 
            fontWeight: 'bold', 
            fontSize: '0.8rem', 
            textTransform: 'none', 
            background: 'linear-gradient(45deg, #27c71b 30%, #462bb6,  90%)', 
            color: 'white',
            '&:hover': {
              background: 'linear-gradient(45deg, #462bb6 30%, #27c71b 90%)',
            },
          }}
        onClick={handleSignUpClick}>
          Sign Up Now
        </Button>
        </Box>
      </Box>
      <Box
        component="img"
        src={FreebieImage}
        alt="Free Documentation"
        sx={{
          flex: 1,
          maxWidth: '300px',
          height: 'auto',
          borderRadius: 1,
          boxShadow: 1,
          opacity: 0.7,
          filter: 'contrast(1.1) brightness(0.9) saturate(1.2) sepia(0.1)',
          mixBlendMode: 'multiply'
        }}
      />
      <Box
        component="img"
        src={CodeImage}
        alt="Upload Code"
        sx={{
          flex: 1,
          maxWidth: '300px',
          height: 'auto',
          borderRadius: 1,
          boxShadow: 1,
          marginLeft: 4,
          opacity: 0.7,
          filter: 'contrast(1.1) brightness(0.9) saturate(1.2) sepia(0.1)',
          mixBlendMode: 'multiply'
        }}
      />
      {/* Blog Previews Section */}
    
    </Box>
  );
};

export default CallToAction;