// src/components/Register/FormComponent.tsx
import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import InputField from './InputField';
import LogoImage from '../../assets/img/logo.png';
import useAuth from '../../hooks/use-auth'; 
import { useNavigate } from 'react-router-dom';

const FormComponent: React.FC = () => {
  const { register, registerStatus, registerError } = useAuth(); 
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState<{ username?: string; email?: string; password?: string }>({});

  const validateForm = () => {
    const newErrors: { username?: string; email?: string; password?: string } = {};
    
    // Username validation: 6-8 characters long
    if (!/^[a-zA-Z0-9]{6,8}$/.test(username)) {
      newErrors.username = 'Username must be 6-8 characters long and contain only letters and numbers.';
    }

    // Email validation: valid email format
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      newErrors.email = 'Please enter a valid email address.';
    }

    // Password validation: at least 12 characters, 2 uppercase, 1 lowercase, 1 special character
    if (!/^(?=.*[a-z])(?=.*[A-Z].*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{12,}$/.test(password)) {
      newErrors.password = 'Password must be at least 12 characters long, contain at least 2 uppercase letters, 1 lowercase letter, and 1 special character.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const navigate = useNavigate();

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (validateForm()) {
      try {
        register({ username, password, email }); 
        
        setUsername('');
        setEmail('');
        setPassword('');
        setErrors({});
        navigate("/otp")
      } catch (error) {
        console.error('Registration failed:', error);
      }
    }
  };

  return (
    <Box sx={{ 
        maxWidth: 400, 
        mx: 'auto', 
        mt: 12, 
        p: 2, 
        border: '1px solid #ccc', 
        borderRadius: 2,
        backgroundColor: 'white'
        }}>
      <a href="/">
        <Box
          component="img"
          src={LogoImage}
          alt="Logo"
          sx={{
            maxWidth: '150px',
            height: 'auto',
            marginLeft: 14,
            borderRadius: 1,
            boxShadow: 1,
            position: 'absolute', 
            top: 50, 
            zIndex: 1, 
          }}
        />
      </a>
      <Typography variant="h4" component="h1" sx={{ textAlign: 'center', mb: 2 }}>
        Register
      </Typography>
      <form onSubmit={handleSubmit}>
        <InputField
          label="Username"
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          error={!!errors.username}
          helperText={errors.username}
        />
        <InputField
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={!!errors.email}
          helperText={errors.email}
        />
        <InputField
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={!!errors.password}
          helperText={errors.password}
        />
        {/*registerError && <Typography color="error">{registerError}</Typography>*/} {/* Display registration error */}
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Register
        </Button>
        <br />
        <Box sx={{
            textAlign: 'center',
            margin: 2
        }}>
            Are you already registered? <a href="/login">Login</a>
        </Box> 
      </form>
    </Box>
  );
};

export default FormComponent;