// src/components/UploadModal.tsx
import React, { useState } from 'react';
import { Modal, Box, Button, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const UploadModal: React.FC<{ open: boolean; onClose: () => void }> = ({ open, onClose }) => {
  const [files, setFiles] = useState<File[]>([]);

  const onDrop = (acceptedFiles: File[]) => {
    setFiles(acceptedFiles);
  };

  const handleUpload = () => {
    // Handle the file upload logic here
    console.log('Files uploaded:', files);
    alert('Files uploaded successfully!'); // Replace with your desired action
    onClose(); // Close the modal after upload
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: { 'application/zip': [] } });

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
          Upload ZIP File
        </Typography>
        <div {...getRootProps()} style={{ border: '2px dashed #ccc', padding: '20px', textAlign: 'center' }}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <Typography variant="body1">Drop the ZIP file here...</Typography>
          ) : (
            <Typography variant="body1">Drag 'n' drop a ZIP file here, or click to select one</Typography>
          )}
        </div>
        <Button variant="contained" onClick={handleUpload} sx={{ mt: 2 }} disabled={files.length === 0}>
          Upload
        </Button>
      </Box>
    </Modal>
  );
};

export default UploadModal;