// src/components/Footer.tsx
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';

const pages = [
  {
      route: "/about",
      label: "About Us",
  },
  {
      route: "/contact",
      label: "Contact",
  },
  {
      route: "/privacy-policy",
      label: "Privacy Policy",
  },
  {
      route: "/terms-of-service",
      label: "Terms of Service",
  },
];

const Footer: React.FC = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static" sx={{ top: 'auto', bottom: 0, backgroundColor: 'white' }}>
      <Toolbar disableGutters>
        {/* Hamburger menu for small screens */}
        <IconButton
          size="large"
          aria-label="menu"
          onClick={handleOpenNavMenu}
          sx={{ display: { xs: 'flex', md: 'none' }, color: '#462bb6' }} // Show only on small screens
        >
          <MenuIcon />
        </IconButton>
        {/* Menu items for larger screens */}
        <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end', flexGrow: 1 }}>
          {pages.map((page) => (
            <Button
              key={page.label}
              onClick={() => navigate(page.route)} // Navigate to the route
              sx={{ my: 2, color: '#462bb6', display: 'block' }}
            >
              {page.label}
            </Button>
          ))}
        </Box>
        {/* Hamburger menu items */}
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
        >
          {pages.map((page) => (
            <MenuItem key={page.label} onClick={() => { handleCloseNavMenu(); navigate(page.route); }}>
              <Typography textAlign="center">{page.label}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Footer;