import React from 'react';
import logo from './logo.svg';
import './App.css';
import HomeScreen from './screens/Home';
import { RouterProvider } from 'react-router-dom';
import { router } from './router';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#462bb6',
    },
    secondary: {
      main: '#27c71b', 
    },
  },
});

function App() {
  const queryClient = new QueryClient();
  return (
    <>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
      </QueryClientProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
