// src/components/Login/OTP.tsx
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import OTPImage from '../../assets/img/otp.jpg';
import useAuth from '../../hooks/use-auth';
import OtpComponent from '../../components/OTP/OTPComponent';
import { Button, Typography } from '@mui/material';

const OTP: React.FC = () => {
  const { verifyOtp } = useAuth();
  const [otp, setOtp] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);

  const handleOtpChange = (value: string) => {
    setOtp(value);
    setError(null); 
  };

  const handleVerifyOtp = async () => {
    try {
      await verifyOtp(otp); 
      setSuccess(true);
    } catch (err) {
      setError('Invalid OTP. Please try again.'); 
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundImage: `url(${OTPImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          borderRadius: 2,
          padding: 2,
          justifyContent: 'center',
          alignItems: 'center',
          boxShadow: 2,
          zIndex: 1,
        }}
      >
        <Typography variant="h4" component="h1" sx={{ mb: 2 }}>
          Enter OTP
        </Typography>
        <OtpComponent /> 
        {error && <Typography color="error">{error}</Typography>}
        {success && <Typography color="success.main">OTP verified successfully!</Typography>}
        <Button variant="contained" color="primary" onClick={handleVerifyOtp} sx={{ mt: 2 }}>
          Verify OTP
        </Button>
      </Box>
    </Box>
  );
};

export default OTP;