import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import { useNavigate } from 'react-router-dom';
import LogoIcon from '../../assets/img/icon.png'; 
import { useTheme } from '@mui/material/styles';

const pages = [
    {
      label: 'Sign Up', 
      route: '/register'
    },
    {
      label: 'Login', 
      route: '/login'
    },
    {
      label: 'How it Works!',
      route: '/how'
    }
];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const Header: React.FC = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [isLoggedIn, setIsLoggedIn] = React.useState(false); 

  const navigate = useNavigate();

  const theme = useTheme();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="relative" sx={{ backgroundColor: 'white' }}>
      <Toolbar disableGutters>
        {/* Icon on the left side */}
        <Box sx={{ flexGrow: 0, display: 'flex', alignItems: 'center' }}>
          <a href="/"><img 
            src={LogoIcon} 
            alt="DocuLearn Icon" 
            style={{ height: '60px', width: 'auto', objectFit: 'contain' }} 
          /></a>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              ml: 2, // Margin left for spacing
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: '#462bb6',
            }}
          >
            DocuLearn
          </Typography>
        </Box>
        {/* Hamburger menu for small screens */}
        <Box sx={{ flexGrow: 1 }} />
        <IconButton
          size="large"
          aria-label="menu"
          onClick={handleOpenNavMenu}
          sx={{ display: { xs: 'flex', md: 'none' }, color: '#462bb6' }} // Show only on small screens
        >
          <MenuIcon />
        </IconButton>
        {/* Menu items for larger screens */}
        <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end' }}>
          {pages.map((page) => (
            <Button
              key={page.label}
              onClick={() => navigate(page.route)}
              sx={{
                my: 2,
                color: theme.palette.primary.main, 
                backgroundColor: 'white',
                borderBottom: `8px solid ${theme.palette.primary.main}`,
                padding: '10px 20px',
                position: 'relative',
                marginRight: '10px',
                fontWeight: 'bolder',
                '&:hover': {
                  backgroundColor: theme.palette.primary.main,
                  color: 'white', 
                  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
                  borderBottom: `8px groove ${theme.palette.secondary.main}`,
                },
                '&:after': {
                  content: '""',
                  display: 'block',
                  width: '100%',
                  height: '4px',
                  backgroundColor: theme.palette.primary.main, 
                  position: 'absolute',
                  bottom: '-4px',
                  left: 0,
                  transition: 'width 0.3s ease'
                },
                '&:hover:after': {
                  width: '100%', 
                },
              }}
            >
              {page.label}
            </Button>
          ))}
        </Box>
        {/* Hamburger menu items */}
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
        >
          {pages.map((page) => (
            <MenuItem key={page.label} onClick={handleCloseNavMenu}>
              <Typography textAlign="center">{page.label}</Typography>
            </MenuItem>
          ))}
        </Menu>
        {isLoggedIn && ( // Conditionally render the avatar based on login status
          <Box sx={{ flexGrow: 0 }}>
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar alt="User Avatar" src="/static/images/avatar/2.jpg" />
            </IconButton>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography sx={{ textAlign: 'center' }}>{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;